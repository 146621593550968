<template>
    <div class="content py-4">
        <div class="loading" v-if="isLoading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="bg"></div>
        <div class="close-wrap">
            <div class="close" @click="$emit('onBack')">
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <img :src="profile.display_image ? profile.display_image : defaultImg" class="rounded-circle mb-2" width="91" height="91" style="object-fit: cover;">
                <div class="">
                    <div class="fc-1 tx-22 lh-33p">สวัสดี</div>
                    <div class="fc-1 tx-20  lh-30p fw-500">{{profile.display_name ? profile.display_name  : '[Display_Name]'}}</div>
                </div>
            </div>
        </div>
        <div class="px-5 my-3">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <div>คะแนนของคุณ</div>
                <div class="fc-1 tx-18  lh-27p fw-700">{{user.point ? (user.point).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}}<span class="fw-500"> คะแนน </span></div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <div>หมดอายุวันที่</div>
                <div>{{user.expired_at ? moment(user.expired_at).format("DD MMMM YYYY") : 'ไม่มีวันหมดอายุ'}}</div>
            </div>
        </div>
        <hr class="line-2">
        <div class="px-5 my-3">
            <div class="text-center fc-1 tx-18 mb-4">ข้อมูลของคุณ</div>
            <div>
                <div class="form-group mb-4">
                    <input
                        type="text"
                        class="form-control form-custom input"
                        placeholder="ชื่อ"
                        v-model="formData.first_name"
                    >
                </div>
                <div class="form-group mb-4">
                    <input
                        type="text"
                        class="form-control form-custom input"
                        placeholder="นามสกุล"
                        v-model="formData.last_name"
                    >
                </div>
                <div class="form-group mb-4">
                    <input
                        type="tel"
                        class="form-control form-custom input"
                        placeholder="เบอร์โทรศัพท์"
                        maxlength="10"
                        v-model="formData.phone_number"
                        @keypress="onTelInput"
                    >
                    <div v-if="formData.phone_number && formData.phone_number.length !== 10" class="text-danger font-size-sm">*เบอร์โทรศัพท์ต้องเป็นตัวเลข 10 ตัวอักษร</div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                    <div class="form-group flex-fill">
                        <select
                            class="form-control form-custom select2 input" 
                            :class="{'place': !formData.date}"
                            v-model="formData.date"
                        >
                            <option value="" selected="" disabled>วัน</option>
                            <option
                                :value="number"
                                v-for="number in 31"
                                :key="number"
                            >
                                {{number}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group flex-fill mx-2">
                        <select
                            class="form-control form-custom select2 input"
                            :class="{'place': !formData.month}"
                            v-model="formData.month"
                        >
                            <option value="" selected="" disabled>เดือน</option>
                            <option
                                :value="month.id"
                                v-for="month in months"
                                :key="month.id"
                            >
                                {{month.label}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group flex-fill">
                        <select
                            class="form-control form-custom select2 input"
                            :class="{'place': !formData.year}"
                            v-model="formData.year"
                        >
                            <option value="" selected="" disabled>ปีเกิด</option>
                            <option
                                :value="year"
                                v-for="year in years"
                                :key="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </div>
                </div>
                <div id="checkheight"></div>
                <div class="text-center mt-4">
                    <div class="mb-2">
                        <button class="btn btn-login w-100" :disabled="!formData.is_valid" @click="onSubmit">อัพเดทข้อมูล</button>
                    </div>
                    <div>
                        <img src="@/assets/images/logo.png" width="111">
                    </div>
                    <div class="mt-4">
                        <!-- <button class="btn btn-sm btn-branch" @click="$emit('onNext')">แจ้งปัญหาการใช้งาน !</button> -->
                        <a class="btn btn-sm btn-branch" href="https://lin.ee/edopWvU">แจ้งปัญหาการใช้งาน !</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modal-success" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body px-2">
                        <div class="fc-3 tx-24 fw-500 text-center mb-5 mt-3">แก้ไขโปรไฟล์สำเร็จ</div>
                        <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                            <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ตกลง</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
moment.locale('th')

import { requestUserUpdate } from '@/apis/user'
import { requestLogActivity } from '@/apis/apis'

export default {
    props: ['profile', 'shop', 'user'],
    data() {
        return {
            moment,
            isLoading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
            formData: {
                is_valid: false,
                first_name: '',
                last_name: '',
                phone_number: '',
                date: '',
                month: '',
                year: '',
            },
            months: [
                {
                    id: '01',
                    label: 'มกราคม',
                },
                {
                    id: '02',
                    label: 'กุมภาพันธ์',
                },
                {
                    id: '03',
                    label: 'มีนาคม',
                },
                {
                    id: '04',
                    label: 'เมษายน',
                },
                {
                    id: '05',
                    label: 'พฤษภาคม',
                },
                {
                    id: '06',
                    label: 'มิถุนายน',
                },
                {
                    id: '07',
                    label: 'กรกฎาคม',
                },
                {
                    id: '08',
                    label: 'สิงหาคม',
                },
                {
                    id: '09',
                    label: 'กันยายน',
                },
                {
                    id: '10',
                    label: 'ตุลาคม',
                },
                {
                    id: '11',
                    label: 'พฤศจิกายน',
                },
                {
                    id: '12',
                    label: 'ธันวาคม',
                }
            ],
        }
    },
    mounted() {
        if(this.user) {
            const date = this.user.birth_date.split("/")
            this.formData = {
                is_valid: false,
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                phone_number: this.user.phone_number,
                date: date[0],
                month: date[1],
                year: date[2],
            }
        }
        this.logActivity('Edit profile')
    },
    computed: {
        years() {
            let years = []
            const yearNow = moment().get('year')
            for(let i = 0 ; i < 60 ; i++) {
                years.push(yearNow - i)
            }
            return years
        },
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.first_name ||
                    !val.last_name ||
                    !val.phone_number ||
                    !val.date ||
                    !val.month ||
                    !val.year ||
                    val.phone_number.length !== 10
                ) {
                    this.formData.is_valid = false
                }
                else {
                    this.formData.is_valid = true
                }
            },
            deep: true
        }
    },
    methods: {
        onTelInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        async onSubmit() {
            this.isLoading = true
            await requestUserUpdate({
                bot_id: this.profile.bot_id,
                mid: this.profile.mid,
                formData : {
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    phone_number: this.formData.phone_number,
                    birth_date: this.formData.date + '/' + this.formData.month + '/' + this.formData.year,
                } ,
                role: 'user',
                cbSuccess : () => {
                    window.$('#modal-success').modal()
                    this.isLoading = false
                } ,
                cbError : () => {
                    this.isLoading = false
                }
            })
        },
        async logActivity(activity) {
            await requestLogActivity({
                formData: {
                    mid: this.profile.mid,
                    bot_id: this.profile.bot_id,
                    activity,
                },
                role: 'user',
                cbSuccess : () => {} ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        }
    }
}
</script>
