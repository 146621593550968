<template>
<fragment>
    <div class="content detail">
        <div class="bg"></div>
        <div class="header">
            <div class="d-flex">
                <!-- <div class="align-self-center left">
                    <div @click="$emit('onBack')">
                        <img src="@/assets/images/arrow-left.png" class="mt-n1" width="10" height="18" >
                    </div>
                </div> -->
                <div class=" pl-3 pr-1 flex-fill flex-grow-1 align-self-center ">
                    <div class="fc-1 tx-18 lh-27p cuttext text-center">รายละเอียดการใช้คูปอง</div>
                </div>
                <!-- <div class=" right">
                </div> -->
            </div>
        </div>
        <div class="px-3 my-3">
            <div class="card card-coupon  ">
                <div class="card-body p-0">
                    <img :src="coupon.img_url" class="w-100">
                </div>
                <div class="card-footer px-2 py-1">
                    <div class="d-flex justify-content-between">
                        <div class="tx-11 lh-16p fc-2 ">
                            <div class="fw-500 cuttext-1">{{coupon.name}}</div>
                            <div class="fw-300 cuttext-2">ใช้ได้ถึงวันที่ : {{coupon.end_date}}</div>
                        </div>
                        <div class="exchange">
                            <div class="btn btn-exchange tx-14 fw-500">แลก <span class="tx-18">{{(coupon.point_cost || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> คะแนน</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-detail">
                <div class="card-body px-3 py-2">
                    <div class="fc-1 tx-18 lh-27p mb-3">{{coupon.name}}</div>
                    <div class="fc-1 tx-14">
                        <div class="collapse" id="more">
                            <div v-if="detailType === 'CONDITION'" v-html="coupon.detail" />
                            <div v-else v-html="coupon.branch" />
                        </div>
                        <div class="text-center mt-2">
                            <a class="btn btn-link p-0 collapsed" data-toggle="collapse" href="#more" role="button" aria-expanded="false" >
                                <div class="arrow"></div>
                            </a>
                        </div>
                    </div>
                    <hr class="line-1 my-2">
                    <div class="d-flex flex-wrap justify-content-center mt-3" v-if="coupon.branch">
                        <button
                            type="button"
                            class="btn mx-1"
                            :class="detailType === 'CONDITION' ? 'btn-condition' : 'btn-branch'"
                            @click="detailType = 'CONDITION'"
                        >
                            เงื่อนไขการรับสิทธิ์
                        </button>
                        <button
                            type="button"
                            class="btn mx-1"
                            :class="detailType === 'BRANCH' ? 'btn-condition' : 'btn-branch'"
                            @click="detailType = 'BRANCH'"
                        >
                            สาขา
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button class="btn btn-privilege" data-toggle="modal" data-target="#modal-Howto">กดเพื่อรับสิทธิ์</button>
            </div>
        </div>
    </div>
    <!-- Modal Howto -->
	<div class="modal fade" id="modal-Howto" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-3">การใช้คูปองที่หน้าร้าน / สาขา</div>
                    <div class="fc-1 tx-14">
                        <div>-การกดใช้งานต้องให้เจ้าหน้าที่กดรับสิทธิ์ ณ จุดขายเท่านั้น</div>
                        <div>-คูปองไม่สามารถนำกลับมาใช้ต่อได้ เมื่อเวลาที่แสดงหมดลง</div>
                        <div>-เมื่อเจ้าหน้าที่กดใช้ ต้องใช้ภายในเวลาที่กำหนดเท่านั้น</div>
                        <div>-หากปิดหน้าแสดงคูปองท่านจะไม่สามารถกลับมาใช้คูปองได้</div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-cancle mx-1" data-dismiss="modal" aria-label="Close">ยกเลิก</button>
                        <button
                            type="button"
                            class="btn btn-confirm mx-1"
                            data-dismiss="modal"
                            @click="onRedeemConfirm"
                        >
                            ยืนยัน
                        </button>
                    </div>
                </div>
            </div>
        </div>
	</div>
    <!-- Modal ref -->
    <div class="modal fade" id="modal-ref" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-3">เลขที่อ้างอิง</div>
                    <div class="fc-1 tx-14 text-center">กรุณาแสดงข้อมูลให้พนักงานเพื่อใช้สิทธิ์</div>
                    <div class="ref-wrap">{{refNo}}</div>
                    <div class="time-count">{{timeCount}}</div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close" @click="$emit('onBack')">ตกลง</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
    <!-- Modal Error -->
    <div class="modal fade" id="modal-error" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="text-center" style="margin-bottom: 24px">
                        <img width="96" src="@/assets/images/error.png" alt="">
                    </div>
                    <div class="fc-3 tx-24 fw-500 text-center mb-6">คูปองหมดแล้ว</div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close" @click="$emit('onBack')">ตกลง</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</fragment>
</template>

<script>
import { requestUserRedeem } from '@/apis/user'
import { requestLogActivity } from '@/apis/apis'

export default {
    props: ['coupon'],
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("USER_PROFILE_DATA")
        return {
            userProfile : profile || {},
            time_start: false,
            timeDefaultSc: 300,
            timeCount: '5:00',
            timeInterVal: null,
            detailType: 'CONDITION',
            refNo: '',
        }
    },
    mounted() {
        this.time_start = false
        this.timeDefaultSc = 300
        this.timeCount = '5:00'
        this.timeInterVal = null
        this.logActivity('Coupon')
    },
    watch: {
        'time_start': {
            handler(val){
                if(val) {
                    this.countTime()
                }
            },
        },
    },
    methods: {
        countTime() {
            this.timeInterVal = setInterval(() => {
                let minutes = parseInt(this.timeDefaultSc / 60, 10);
                let seconds = parseInt(this.timeDefaultSc % 60, 10);
                seconds = seconds < 10 ? "0" + seconds : seconds
                this.timeCount = minutes + ":" + seconds
                if (--this.timeDefaultSc < 0) {
                    clearInterval(this.timeInterVal)
                    window.$('#modal-ref').modal('hide')
                    this.$emit('onBack')
                }
            }, 1000);
        },
        async onRedeemConfirm() {
            const ref_no = this.rendomRef(8)
            this.refNo = ref_no
            await requestUserRedeem({
                bot_id: this.userProfile.bot_id,
                formData: {
                    mid: this.userProfile.mid,
                    coupon_id: this.coupon.id,
                    ref_no: ref_no,
                    point: this.coupon.point_cost,
                },
                mid: this.userProfile.mid,
                role: 'user',
                cbSuccess : res => {
                    if(res.data.status === 'CREATE_ERROR' && res.data.reason === 'Limit exceeded') {
                        window.$('#modal-error').modal()
                    }
                    else {
                        window.$('#modal-ref').modal()
                        this.time_start = true
                    }
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        },
        rendomRef(length) {
            let result = ''
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            const charactersLength = characters.length
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result
        },
        async logActivity(activity) {
            await requestLogActivity({
                formData: {
                    mid: this.userProfile.mid,
                    bot_id: this.userProfile.bot_id,
                    activity,
                },
                role: 'user',
                cbSuccess : () => {} ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        }
    },
    destroyed() {
        clearInterval(this.timeInterVal)
    }
}
</script>

<style scoped>
.ref-wrap {
    background-color: #FFFFFF;
    border: 1px solid #4D4D4D;
    border-radius: 8px;
    padding: 10px 24px;
    width: fit-content;
    font-size: 30px;
    color: #4D4D4D;
    margin: 18px auto 2px;
    text-transform: uppercase;
}
.time-count {
    color: rgba(255, 86, 86, 1);
    font-size: 26px;
    text-align: center;
}
</style>