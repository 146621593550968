<template>
    <loading v-if="isLoading"/>
    <div class="content" v-else>
        <div class="bg"></div>
        <div class="header py-3" style="border-bottom: 1px solid rgba(160,160,160,0.5)">
            <div class="d-flex my-2 align-items-center justify-content-center">
                <div class="">
                    <img :src="displayShop.shop_img_url ? displayShop.shop_img_url : defaultImgLogo" class="rounded-circle" width="56" height="56" style="object-fit: cover;">
                </div>
                <div class=" pl-3 pr-1 ">
                    <div class="fc-1 tx-20 fw-500 cuttext">{{displayShop.shop_name ? displayShop.shop_name : 'STORE NAME'}}</div>
                </div>
            </div>
        </div>
        <div style="text-align: center;margin-top: 160px;">
            <div class="mb-4" style="font-size: 26px">กรุณากดเพื่อลงทะเบียน</div>
            <div><button class="btn btn-login mb-2 px-5" @click="register">ลงทะเบียน</button></div>
        </div>
        <div class="bottom px-3 my-5">
            <div class="text-center mb-3">
                <img src="@/assets/images/logo.png" width="111">
            </div>
            <div class="text-center tx-14 lh-21p fc-1 mb-2">
                <div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
            </div>
            
        </div>
</div>
</template>

<script>
import queryString from 'query-string'

import { requestShopInfo } from '@/apis/shop'
import { requestLogActivity } from '@/apis/apis'

export default {
    data() {
        return {
            id: '',
            bot_id: '',
            mid: '',
            displayShop: {},
            isLoading: false,
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
        }
    },
    mounted() {
        const oaData = queryString.parse(location.search)
        if(oaData.id) {
            this.id = oaData.id
        }
        if(oaData.bot_id) {
            this.bot_id = oaData.bot_id
        }
        if(oaData.mid) {
            this.mid = oaData.mid
        }
        this.onLoad()
    },
    methods: {
        register(){
            window.location = `https://line.me/R/oaMessage/@${this.id}/?ลงทะเบียน%20สะสมคะแนน`
        },
        onClose() {
            // eslint-disable-next-line no-undef
            liff.closeWindow()
        },
        async onLoad() {
            this.isLoading = true
            await requestShopInfo({
                bot_id: this.bot_id,
                role: 'user',
                cbSuccess : res => {
                    this.displayShop = res.data.data
                    this.isLoading = false
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            await requestLogActivity({
                formData: {
                    mid: this.mid,
                    bot_id: this.bot_id,
                    activity: 'Instruction',
                },
                role: 'user',
                cbSuccess : () => {
                    this.isLoading = false
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
        }
    },
}
</script>