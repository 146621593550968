<template>
    <div class="content py-4">
        <div class="loading" v-if="isLoading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="bg"></div>
        <div class="close-wrap">
            <div class="close" @click="$emit('onBack')">
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <img :src="profile.display_image ? profile.display_image : defaultImg" class="rounded-circle mb-2" width="91" height="91" style="object-fit: cover;">
                <div class="">
                    <div class="fc-1 tx-22 lh-33p">สวัสดี</div>
                    <div class="fc-1 tx-20  lh-30p fw-500">{{profile.display_name ? profile.display_name  : '[Display_Name]'}}</div>
                </div>
            </div>
        </div>
        <hr class="line-2">
        <div class="px-5 my-3">
            <div class="text-center fc-1 tx-18 mb-4">แจ้งปัญหา</div>
            <div>
                <div class="form-group mb-4">
                    <select
                        class="form-control form-custom select2 input"
                        :class="{'place': !formData.topic}"
                        v-model="formData.topic"
                    >
                        <option value="" selected="" disabled>-- เลือกหัวข้อ --</option>
                        <option value="Title 1">Title 1</option>
                        <option value="Title 2">Title 2</option>
                        <option value="Title 3">Title 3</option>
                        <!-- <option
                            :value="month.id"
                            v-for="month in months"
                            :key="month.id"
                        >
                            {{month.label}}
                        </option> -->
                    </select>
                </div>
                <div class="form-group mb-4">
                    <textarea
                        type="text"
                        class="form-control form-custom input"
                        placeholder="รายละเอียด ..."
                        rows="8"
                        v-model="formData.detail"
                    />
                </div>
                <upload-image
                    label="แนบรูปภาพ"
                    :size="10"
                    v-model="formData.img_url"
                />
                <div id="checkheight"></div>
                <div class="footer " id="footer-bottom">
                    <div class="text-center">
                        <img src="@/assets/images/logo.png" width="111">
                    </div>
                    <div class="text-center">
                        <button class="btn btn-login w-100" :disabled="!formData.is_valid" @click="onSubmit">ส่งข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modal-success" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body px-2">
                        <div class="fc-3 tx-24 fw-500 text-center mb-5 mt-3">แจ้งปัญหาสำเร็จ</div>
                        <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                            <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ตกลง</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { requestUserReport } from '@/apis/user'

export default {
    props: ['profile', 'shop'],
    data() {
        return {
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
            isLoading: false,
            formData: {
                is_valid: false,
                topic: '',
                detail: '',
                img_url: '',
            },
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.topic ||
                    !val.detail ||
                    !val.img_url
                ) {
                    this.formData.is_valid = false
                }
                else {
                    this.formData.is_valid = true
                }
            },
            deep: true
        }
    },
    methods: {
        async onSubmit() {
            this.isLoading = true
            await requestUserReport({
                bot_id: this.profile.bot_id,
                formData : {
                    topic: this.formData.topic,
                    detail: this.formData.detail,
                    img_url: this.formData.img_url,
                } ,
                mid: this.profile.mid,
                role: 'user',
                cbSuccess : () => {
                    window.$('#modal-success').modal()
                    this.isLoading = false
                    this.formData = {
                        is_valid: false,
                        topic: '',
                        detail: '',
                        img_url: '',
                    }
                } ,
                cbError : () => {}
            })
        }
    }
}
</script>