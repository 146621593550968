<template>
    <div class="content">
        <div class="bg"></div>
        <div style="text-align: center;margin-top: 200px;">
            <div class="mb-4" style="font-size: 26px">เกิดข้อผิดพลาดในการเข้าสู่ระบบ</div>
        </div>
        <div class="bottom px-3 my-5">
            <div class="text-center mb-3">
                <img src="@/assets/images/logo.png" width="111">
            </div>
            <div class="text-center tx-14 lh-21p fc-1 mb-2">
                <div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
            </div>
            
        </div>
</div>
</template>

<script>
export default {
}
</script>