<template>
    <div class="text-center p-4">
        <button type="button" class="btn btn-login w-100" @click="onClose">ปิดหน้าต่างนี้</button>
    </div>
</template>

<script>
export default {
    methods: {
        async onClose() {
            // eslint-disable-next-line no-undef
            await liff.closeWindow()
        }
    }
}
</script>