<template>
<fragment>
    <loading v-if="isLoading"/>
    <div>
        <div v-if="errorMessage!==null">{{ errorMessage }}</div>
        <register
            v-if="pageType === 'REGISTER'"
            :profile="userProfile"
            :user="displayUserInfo"
            :shop="displayShop"
            @onNext="onRegisterNext"
        />
        <home
            v-else-if="pageType === 'HOME'"
            :profile="userProfile"
            :user="displayUserInfo"
            :shop="displayShop"
            :coupons="displayCoupons"
            @onNext="onHomeNext"
            @goProfile="pageType = 'PROFILE'"
        />
        <coupon
            v-else-if="pageType === 'COUPON'"
            :coupon="displayCoupon"
            @onBack="onCouponBack"
        />
        <profile
            v-else-if="pageType === 'PROFILE'"
            :profile="userProfile"
            :user="displayUserInfo"
            :shop="displayShop"
            @onBack="pageType = 'HOME'"
            @onNext="pageType = 'REPORT'"
        />
        <problem-report
            v-else-if="pageType === 'REPORT'"
            :profile="userProfile"
            :user="displayUserInfo"
            :shop="displayShop"
            @onBack="pageType = 'PROFILE'"
        />
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-error" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-5 mt-3">คะแนนไม่พอ</div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ตกลง</button>
                    </div>
                </div>
            </div>
        </div>
	</div>

    <!-- Modal -->
    <div class="modal fade" id="modal-point" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-3">ยินดีต้อนรับสมาชิกใหม่</div>
                    <div class="fc-1 tx-20 text-center">คุณได้รับ คะแนนพิเศษ</div>
                    <div class="ref-wrap">{{(displayShop.first_login_point || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} <span class="fw-500 tx-24"> คะแนน </span></div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ยืนยัน</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</fragment>
</template>

<script>
import Register from '@/components/Register'
import Home from '@/components/Home'
import Coupon from '@/components/Coupon'
import Profile from '@/components/Profile'
import ProblemReport from '@/components/ProblemReport'

import { requestUserInfo } from '@/apis/user'
import { requestShopInfo, requestCouponList, requestCouponInfo } from '@/apis/shop'

export default {
    components: {
        Register,
        Home,
        Coupon,
        Profile,
        ProblemReport,
    },
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("USER_PROFILE_DATA")
        return {
            userProfile : profile || {},
            pageType: null,
            isLoading : false ,
            errorMessage : null,
            displayCoupon: {},
            displayUserInfo: {},
            displayShop: {},
            displayCoupons: [],
        }
    },
    methods: {
        async onLoad() {
            this.isLoading = true
            await requestUserInfo({
                bot_id: this.userProfile.bot_id,
                mid: this.userProfile.mid,
                role: 'user',
                cbSuccess : res => {
                    this.displayUserInfo = {
                        ...res.data.data,
                        get_point_url: `https://liff.line.me/${process.env.VUE_APP_LIFF_ID}/staff/?bot_id=${this.userProfile.bot_id}&user_mid=${this.userProfile.mid}`
                    }
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
            await requestShopInfo({
                bot_id: this.userProfile.bot_id,
                mid: this.userProfile.mid,
                role: 'user',
                cbSuccess : res => {
                    this.displayShop = res.data.data
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            await requestCouponList({
                bot_id: this.userProfile.bot_id,
                formData : {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 1000,
                    filter: {},
                    mid: this.userProfile.mid
                },
                mid: this.userProfile.mid,
                role: 'user',
                cbSuccess : res => {
                    this.isLoading = false
                    this.displayCoupons = res.data.rows
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            if(this.displayUserInfo.is_register === 0) {
                this.pageType = 'REGISTER'
            }
            else {
                this.pageType = 'HOME'
            }
        },
        onRegisterNext() {
            this.pageType = 'HOME'
            this.onLoad()
            if(this.displayShop.first_login_point > 0 && this.displayUserInfo.is_register === 0 && this.pageType === 'HOME' ) {
                window.$('#modal-point').modal()
            }
        },
        
        async onHomeNext(to, coupon) {
            if(to === 'coupon' && coupon.point_cost > this.displayUserInfo.point) {
                window.$('#modal-error').modal()
            }
            else if(to === 'coupon' && coupon.point_cost <= this.displayUserInfo.point) {
                this.pageType = 'COUPON'
                await requestCouponInfo({
                    bot_id: this.userProfile.bot_id,
                    id: coupon.id,
                    mid: this.userProfile.mid,
                    role: 'user',
                    cbSuccess : res => {
                        this.displayCoupon = res.data.data
                    } ,
                    cbError : (_e , msg) => {
                        this.errorMessage = msg
                        this.isLoading = false
                    }
                })
            }
            else if(to === 'profile') {
                this.pageType = 'PROFILE'
            }
        },
        onCouponBack() {
            this.pageType = 'HOME'
            this.onLoad()
        },
    },
    mounted() {
        this.onLoad()
    },
}
</script>

<style scoped>
.ref-wrap {
    background-color: #FFFFFF;
    border: 1px solid #4D4D4D;
    border-radius: 8px;
    padding: 10px 24px;
    width: fit-content;
    font-size: 28px;
    color: #4D4D4D;
    margin: 18px auto 2px;
    text-transform: uppercase;
}
.time-count {
    color: rgba(255, 86, 86, 1);
    font-size: 24px;
    text-align: center;
}
</style>