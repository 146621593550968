<template>
<fragment>
    <div class="content">
        <div class="bg"></div>
        <div class="header">
            <div class="d-flex">
                <div class="align-self-center left" @click="$emit('goProfile')">
                    <img :src="profile.display_image ? profile.display_image : defaultImg" class="rounded-circle" width="56" height="56" style="object-fit: cover;">
                </div>
                <div class=" pl-3 pr-1 flex-fill flex-grow-1 align-self-center ">
                    <div class="fc-1 tx-14 lh-21p cuttext">สวัสดี <span class="fw-500">{{profile.display_name ? profile.display_name  : '[Display_Name]'}}</span></div>
                    <div class="fc-1 tx-18  lh-27p fw-700">{{(user.point || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}<span class="fw-500"> คะแนน </span></div>
                </div>
                <div class="ml-auto text-center right">
                    <button class="btn btn-link p-0" data-toggle="modal" data-target="#qrModal" data-backdrop="static" data-keyboard="false">
                        <qrcode-vue
                            :value="user.get_point_url"
                            :size="34"
                        />
                        <!-- <img src="@/assets/images/qr-2.png" width="48" height="48"> -->
                    </button>
                    <div class="tx-9">QR Code ของฉัน</div>
                </div>
            </div>
        </div>
        <div class="banner">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                <li
                    data-target="#carouselExampleIndicators"
                    :data-slide-to="index"
                    :class="{'active': index === 0}"
                    v-for="(banner, index) in shop.banners"
                    :key="index"
                />
                </ol>
                <div class="carousel-inner">
                <div
                    class="carousel-item"
                    :class="{'active': index === 0}"
                    v-for="(banner, index) in shop.banners"
                    :key="index"
                >
                    <img :src="banner.img_url" class="d-block w-100" alt="">
                </div>
                </div>
            </div>
        </div>
        <div class="px-3 my-3">
            <div
                class="card card-coupon"
                :class="{'used': coupon.status === 'redeemed', 'soldout': coupon.status === 'out of stock'}"
                v-for="(coupon, index) in coupons"
                :key="index"
                style="margin-bottom: 50px;"
                @click="coupon.status === 'available' ? $emit('onNext', 'coupon', coupon) : false"
            >
                <div class="card-body p-0">
                    <img :src="coupon.img_url" class="w-100">
                </div>
                <div class="card-footer px-2 py-1">
                    <div class="d-flex justify-content-between">
                        <div class="tx-11 lh-16p fc-2 ">
                            <div class="fw-500 cuttext-1">{{coupon.name}}</div>
                            <div class="fw-300 cuttext-2">ใช้ได้ถึงวันที่ : {{coupon.end_date}}</div>
                        </div>
                        <div class="exchange">
                            <button type="button" class="btn btn-exchange tx-14 fw-500">แลก <span class="tx-18">{{(coupon.point_cost || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> คะแนน</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center tx-14 lh-21p fc-1 mb-2">
                <div>Power by: Yellow Idea Co., Ltd</div>
                <div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
            </div>
            <div class="text-center">
                <img src="@/assets/images/logo.png" width="111">
            </div>
        </div>
    </div>
    <!-- Modal -->
	<div class="modal fade" id="qrModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-qrcode modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <qrcode-vue
                        :value="user.get_point_url"
                        :size="200"
                    />
                </div>
                <div class="text-center">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="@/assets/images/close-w.png" width="32"	height="32">
                    </button>
                </div>
            </div>
        </div>
	</div>
</fragment>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

import { requestLogActivity } from '@/apis/apis'

export default {
    props: ['profile', 'shop', 'coupons', 'user'],
    components: {
        QrcodeVue,
    },
    data() {
        return {
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
        }
    },
    mounted() {
        this.logActivity('Home')
    },
    methods: {
        async logActivity(activity) {
            await requestLogActivity({
                formData: {
                    mid: this.profile.mid,
                    bot_id: this.profile.bot_id,
                    activity,
                },
                role: 'user',
                cbSuccess : () => {} ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        }
    },
}
</script>

<style scoped>
.btn-link {
    border: 1px solid rgb(0, 0, 0);
    border-radius: 12px;
    padding: 6px 6px 0 !important;
    margin-bottom: 4px;
}
</style>